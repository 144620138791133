<template>
  <div class="page row">
    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="d-flex mb-3">
            <b-form-group
              class="select col-3 align-center mb-0 pl-0"
              label="Select Campaign"
              label-for="input11"
            >
              <b-form-select
                :options="campaigns"
                field-label="name"
                field-value="value"
                v-model="selected"
                @change="loadLog"
              />
            </b-form-group>
            <b-form-group
              class="select col-3 align-center mb-0 pl-0"
              label-for="input11"
              label="Search Number"
            >
              <b-form-input size="sm" v-model="phone" @input="loadLog" />
            </b-form-group>
             <b-form-group
              class="select col-3 align-center mb-0 pl-0"
              label-for="input11"
              label="Caller ID #1"
            >
              <b-form-input size="sm" v-model="ani1" @input="loadLog" />
            </b-form-group>
             <b-form-group
              class="select col-3 align-center mb-0 pl-0"
              label-for="input11"
              label="Caller ID #2"
            >
              <b-form-input size="sm" v-model="ani2" @input="loadLog" />
            </b-form-group>
            <!-- <b-form-group
              label="Start date"
              class="date-call-log select col-3 align-center"
            >
              <datetime
                v-model="date.start"
                type="datetime"
                :auto="true"
                @input="loadLog"
              ></datetime>
            </b-form-group> -->
            <!-- <b-form-group
              label="End date"
              class="date-call-log select col-3 align-center"
            >
              <datetime
                v-model="date.end"
                type="datetime"
                :auto="true"
                @input="validEndDate(), loadLog()"
              ></datetime>
            </b-form-group> -->
          </div>

          <div class="table-responsive" v-if="!$store.state.loading">
            <div class="col-12 pl-0" v-if="!logs || logs.length == 0">
              No Data Found
            </div>
            <table class="table center-aligned-table" v-else>
              <thead>
                <tr>
                  <th
                    class="sortable"
                    @click="
                      (completedSort = !completedSort), sortable('completed')
                    "
                  >
                    Called On
                  </th>
                  <th>Caller ID #1</th>
                  <th>Caller ID #2</th>
                  <th
                    class="sortable"
                    @click="(dnisSort = !dnisSort), sortable('dnis')"
                  >
                    DNIS
                  </th>
                  <th>Status</th>
                  <!-- <th>Groups</th> -->
                  <!-- <th>Status</th> -->
                </tr>
              </thead>
              <tbody v-if="logs">
                <tr v-for="item in logs" :key="item.contact_id">
                  <td>{{ getDate(item.completed) }}</td>
                  <td>{{ item.ani1 }}</td>
                  <td>{{ item.ani2 }}</td>
                  <td>{{ item.dnis }}</td>
                  <td>{{ getStatus(item.status) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { mapGetters } from "vuex";

export default {
  components: {
    Datetime,
  },
  data() {
    return {
      ani1: null,
      ani2: null,
      date: {
        end: null,
        start: null,
      },
      selected: "All",
      logs: null,
      phone: null,
      key: null,
      completedSort: false,
      dnisSort: false,
      load: false,
    };
  },
  async mounted() {
    this.initDate();
    this.load = true;
    await this.$store.dispatch("loadItems");
    const params = await this.$route.params.camp;
    if (params) {
      this.selected = params.trim();
    }
    this.loadLog();
  },
  computed: {
    ...mapGetters(["admin", "loading", "items"]),
    campaigns() {
      let items = [
        {
          value: "All",
          text: "All",
        },
      ];
      this.items.forEach((el) => {
        let obj = {
          value: el.id,
          text: el.name,
        };
        items.push(obj);
      });
      return items;
    },
  },
  methods: {
    initDate() {
      let today = new Date();
      const first_date = today.setHours(0, 0, 0, 0);
      const last_date = today.setHours(23, 59, 0, 0);
      this.date.start = this.$moment(first_date).toISOString();
      this.date.end = this.$moment(last_date).toISOString();
    },
    loadLog() {
      if (this.load) {
        this.$store.commit("updateLoading", true);

        let payload = {};
        if (this.selected && this.selected !== "All") {
          payload.campaign_id = this.selected;
        }
        if (this.phone) {
          payload.dnis = this.phone;
        }
        if(this.ani1) {
          payload.ani1 = this.ani1
        }
        if(this.ani2) {
          payload.ani2 = this.ani2
        }
        // if (this.date.start) {
        //   payload["period"] = [this.toUnix(this.date.start)];
        //   let end = this.date.end
        //     ? this.toUnix(this.date.end)
        //     : this.toUnix(this.$moment());
        //   payload.period.push(end);
        // }
        this.$axios
          .post(`${this.admin}/call/query`, payload)
          .then((res) => {
            this.logs = res.data.calls;
            this.$store.commit("updateLoading", false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    sortable(key) {
      let items = this.logs;
      this.logs = null;
      this.key = key;
      items.sort(this.compare);
      this.logs = items;
    },
    compare(a, b) {
      const type = this.key == "completed" ? this.completedSort : this.dnisSort;
      const item1 = a[this.key];
      const item2 = b[this.key];

      let comparison = 0;
      if (type) {
        if (item1 > item2) {
          comparison = 1;
        } else if (item1 < item2) {
          comparison = -1;
        }
      } else {
        if (item1 < item2) {
          comparison = 1;
        } else if (item1 > item2) {
          comparison = -1;
        }
      }
      return comparison;
    },
    toUnix(date) {
      return this.$moment(date).unix();
    },
    getDate(date) {
      return this.$moment.unix(date).format("MMMM Do YYYY, hh:mm");
    },
    validEndDate() {
      if (this.date.end < this.date.start) {
        this.date.end = this.date.start;
      }
    },
    getStatus(status) {
      let item;
      switch (status) {
        case 0: {
          item = "Success";
          break;
        }
        case 1: {
          item = "Unsuccessful Delivery";
          break;
        }
        case 2: {
          item = "Unable to Detect Voicemail";
          break;
        }
        case 3: {
          item = "Calling timeout reached";
          break;
        }
        case 4: {
          item = "Exception caught";
          break;
        }
        case 5: {
          item = "In progress";
          break;
        }
      }
      return item;
    },
  },
};
</script>
<style scoped>
.custom-select {
  font-size: 13px;
}
.form-control-sm {
  height: 2rem;
}
.custom-select {
  max-height: 32px;
}
</style>
<style lang="scss">
.form-group.date-call-log {
  margin-bottom: 0;
  padding-left: 0;
  .col-form-label {
    padding-bottom: 0.5rem;
    line-height: normal;
    vertical-align: middle;
    font-size: 0.813rem;
    line-height: 1;
    vertical-align: top;
  }
  input {
    border: 1px solid #f6f2f2;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    line-height: 14px;
    font-weight: 300;
    height: 2rem;
    width: 100%;
  }
}
.sortable {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right center;
}
</style>